<template>
  <div>
    <div v-if="bhyt" class="box-number flex justify-center items-center">
      <div
        class="box-item-bhyt"
        v-for="(number, index) in getBHYT()"
        :key="index"
      >{{number || '&nbsp;'}}</div>
    </div>
    <div v-else class="box-number flex justify-center items-center">
      <div
        class="box-item"
        v-for="(number, index) in getNumber()"
        :key="index"
      >{{ isNaN(number) && typeof number !== 'string' ? '&nbsp;' : number}}</div>
      <div v-if="str === null || typeof str === 'undefined'|| str===''" class="box-item"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoxNumber',
  props: ['str', 'bhyt'],
  methods: {
    getNumber () {
      if (this.str !== null && typeof this.str !== 'undefined') {
        return String(this.str).split('')
      }
    },
    getBHYT () {
      if (!this.str) return ['', '', '', '', '', '']
      const strNumber = [5, 8, 10, 12, 13, this.str.length]
      const subStr = []
      let count = 0
      for (const num of strNumber) {
        subStr.unshift(
          this.str.substring(this.str.length - num, this.str.length - count)
        )
        count = num
      }
      return subStr
    }
  }
}
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.justify-center {
  justify-content: center;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.box-number {
  border: 1px solid black;
  height: 1.5rem !important;
}
.box-item {
  font-family: 'Times New Roman', Tinos, Times, serif !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-right: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-item-bhyt {
  font-family: 'Times New Roman', Tinos, Times, serif !important;
  height: 1.5rem !important;
  /* min-width: 1.5rem !important; */
  padding: 2px;
  border-right: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 65px !important;
}
.box-item:last-child,
.box-item-bhyt:last-child {
  border-right: none;
}
</style>
