<template>
  <button v-on="$listeners" class="flex items-center justify-center border-0 focus:outline-none" :class="className">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 9.16659H10.8333V4.16659C10.8333 3.94557 10.7455 3.73361 10.5892 3.57733C10.433 3.42105 10.221 3.33325 9.99998 3.33325C9.77897 3.33325 9.567 3.42105 9.41072 3.57733C9.25444 3.73361 9.16665 3.94557 9.16665 4.16659V9.16659H4.16665C3.94563 9.16659 3.73367 9.25438 3.57739 9.41066C3.42111 9.56694 3.33331 9.7789 3.33331 9.99992C3.33331 10.2209 3.42111 10.4329 3.57739 10.5892C3.73367 10.7455 3.94563 10.8333 4.16665 10.8333H9.16665V15.8333C9.16665 16.0543 9.25444 16.2662 9.41072 16.4225C9.567 16.5788 9.77897 16.6666 9.99998 16.6666C10.221 16.6666 10.433 16.5788 10.5892 16.4225C10.7455 16.2662 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0543 10.8333 16.2663 10.7455 16.4226 10.5892C16.5788 10.4329 16.6666 10.2209 16.6666 9.99992C16.6666 9.7789 16.5788 9.56694 16.4226 9.41066C16.2663 9.25438 16.0543 9.16659 15.8333 9.16659Z"
        fill="currentColor"
      />
    </svg>

    <div class="mt-0.25 ml-1.5 text-sm font-medium">{{title}}</div>
  </button>
</template>

<script>
export default {
  name: 'ButtonHoDo',
  props: ['title', 'className']
}
</script>