<template>
    <div v-if="data" class="hodo-breadcrumb d-flex">
        <div class="hodo-breadcrumb-icon">
            <img v-if="data.icon" :src="require('../../public/assets/images/icon/breadcrumb/' + data.icon)" alt="" class="mr-2">
        </div>
        <div class="content-center-height">
            <a class="hodo-breadcrumb-item mr-2" v-for="(page) in data.pages" href="javascript:;" :class="page.active ? 'txt-pri border-bottom bd-pri' : 'text--info'" @click="goToPage(page)">
            {{page.text}}
            <img v-if="!page.active" src="../../public/assets/images/icon/right.svg" alt="" class="mr-1 ml-1">
        </a>
        </div>
    </div>
</template>
<script>
export default {

  name: 'Breadcrumb',
  props: ['data'],
  data () {
    return {

    }
  },
  methods: {
    goToPage (page) {
      if (page && page.path) {
        // if (page.prev) {
        //   this.$router.go(-1)
        // } else {
        //   this.$router.push({ path: page.path })
        // }
        this.$router.push({ path: page.path })
      }
    }
  }
}
</script>
<style lang="css" scoped>
.hodo-breadcrumb-icon img {
    width: 36px;
    height: 36px;
}

.hodo-breadcrumb-item {
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

}
.hodo-breadcrumb-item:hover {
	color: #20419B;
	text-decoration: none;
}
.hodo-breadcrumb-item img {
	width: 7px;
	height: 14px;
}
</style>