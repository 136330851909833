<template>
  <div
    class="modal fade bd-example-modal-lg"
    id="CalendarKyc"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="d-flex justify-content-end bg-white border-20px">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-dismiss="modal"
            aria-label="Close"
            class="mt-3 mr-3 cursor-pointer"
          >
            <path
              d="M6 17.9998L17.9998 6"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.9998 17.9998L6 6"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="modal-body">
          <div class="row container px-4">
            <div class="col-md-4 d-flex align-items-center">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="24" fill="#45B36B" />
                <path
                  d="M18 24L22 28L30 20"
                  stroke="#FCFCFD"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="mt-3 ml-2">{{$t('KYC_steps.btn_submit')}}</p>
            </div>
            <div class="col-md-4">
              <svg
                class="my-4"
                width="120"
                height="24"
                viewBox="0 0 96 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5V10.5ZM95.0607 13.0607C95.6464 12.4749 95.6464 11.5251 95.0607 10.9393L85.5147 1.3934C84.9289 0.807611 83.9792 0.807611 83.3934 1.3934C82.8076 1.97919 82.8076 2.92893 83.3934 3.51472L91.8787 12L83.3934 20.4853C82.8076 21.0711 82.8076 22.0208 83.3934 22.6066C83.9792 23.1924 84.9289 23.1924 85.5147 22.6066L95.0607 13.0607ZM2 13.5H94V10.5H2V13.5Z"
                  fill="#E4E7EC"
                />
              </svg>
            </div>
            <div class="col-md-4 d-flex align-items-center">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="24" fill="#20409B" />
                <path
                  d="M31 16H30V14H28V16H20V14H18V16H17C15.9 16 15 16.9 15 18V32C15 33.1 15.9 34 17 34H31C32.1 34 33 33.1 33 32V18C33 16.9 32.1 16 31 16ZM31 32H17V22H31V32ZM17 20V18H31V20H17ZM19 24H29V26H19V24ZM19 28H26V30H19V28Z"
                  fill="#FCFCFD"
                />
              </svg>
              <p class="mt-3 ml-2">{{$t('KYC_steps.lbl_authentication')}}</p>
            </div>
            <p class="fs-18 fw-500 txt-black text-center my-3">{{$t('KYC_steps.lbl_kyc_hodo_msg')}}</p>
          </div>

          <div class="row" v-show="step === 1">
            <div class="col-md-7 mt-3">
              <v-date-picker
                v-model="selected_date"
                class="border-0"
                :min-date="new Date()"
                is-expanded
              />
            </div>
            <div class="col-md-5">
              <label class="ml-2 mt-4 mb-2 robo-18-500 txt-black">
                {{
                formatDMY(selected_date)
                }}
              </label>
              <div class="p-2 appt-time-slot">
                <div v-for="time in timeslots" :key="time.id" @click="clickTime(time)">
                  <div class="time-slot time-slot-df" v-if="!show_slot || show_slot.id !== time.id">
                    <p class="robo-20-500 mb-0 txt-pri">{{ time.start_time.format("HH:mm") }}</p>
                  </div>

                  <div
                    class="time-slot time-slot-mouseover row"
                    v-if="show_slot && show_slot.id === time.id"
                  >
                    <div class="col-6 pr-1">
                      <div class="h-100 d-flex justify-content-center align-items-center time">
                        <p class="robo-20-500 mb-0 text-white">{{ time.start_time.format("HH:mm") }}</p>
                      </div>
                    </div>
                    <div class="col-6 pl-1">
                      <div
                        class="h-100 d-flex justify-content-center align-items-center bg-pri"
                        @click="showModal"
                      >
                        <p class="robo-20-500 mb-0 text-white">{{$t('KYC_steps.lbl_select')}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class v-show="step === 2">
            <div class="d-flex align-items-center">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 cursor-pointer"
                @click="back"
              >
                <path
                  d="M20.0576 11H7.88762L13.4776 5.41L12.0576 4L4.05762 12L12.0576 20L13.4676 18.59L7.88762 13H20.0576V11Z"
                  fill="black"
                />
              </svg>
              <p
                class="fs-18 fw-700 txt-black text-center my-3 cursor-pointer-custom"
              >{{$t('KYC_steps.lbl_appt_confirm')}}</p>
            </div>
            <div class="row">
              <div class="col-md-12 mt-3">
                <p class="fs-16 fw-500 txt-black">{{$t('KYC_steps.lbl_time')}}</p>
                <div class="d-flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                  >
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z"
                      fill="#20409B"
                    />
                  </svg>
                  <p class="fs-16 fw-400 txt-black">30 {{$t('KYC_steps.lbl_min')}}</p>
                </div>
                <div class="d-flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                  >
                    <path
                      d="M19 4H18V2H16V4H8V2H6V4H5C3.9 4 3 4.9 3 6V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM5 8V6H19V8H5ZM7 12H17V14H7V12ZM7 16H14V18H7V16Z"
                      fill="#20409B"
                    />
                  </svg>
                  <p class="fs-16 fw-400 txt-black">
                    {{
                    show_slot
                    ? show_slot.start_time.format("HH:mm")
                    : "--:--"
                    }},
                    {{
                    selected_date ? formatDMY(selected_date) : "--/--/----"
                    }}
                  </p>
                </div>
              </div>
              <!-- <div class="col-md-7 mt-3">
                    <p class="fs-16 fw-600 txt-black">Thông tin liên hệ</p>
                    <div class="mt-3">
                        <label
                        for="txt-name"
                        class="fs-16 fw-600 txt-grey-600"
                        >Họ và tên</label>
                        <input
                        type="text"
                        class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
                        placeholder="Nhập họ và tên"
                        autocomplete="off"
                        />
                    </div>
                    <div class="mt-3">
                        <label
                        for="txt-name"
                        class="fs-16 fw-600 txt-grey-600"
                        >Email</label>
                        <input
                        type="text"
                        class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
                        placeholder="Nhập email"
                        autocomplete="off"
                        />
                    </div>
                    <div class="mt-3">
                        <label
                        for="txt-name"
                        class="fs-16 fw-600 txt-grey-600"
                        >Số điện thoại</label>
                        <input
                        type="text"
                        class="form-control form-input mt-2 fs-16 fw-500 text-grey-600 bg-white"
                        placeholder="Nhập Số điện thoại"
                        autocomplete="off"
                        />
                    </div>
              </div>-->
            </div>
            <div class="mt-5 mb-2">
              <button
                class="fs-16 fw-600 btn bg-pri bd-pri text-white btn-l w-100 border-radius-18px"
                type="button"
                @click="createDoctorKYC"
                :disabled="loading"
              >
                {{$t('KYC_steps.lbl_book_appt')}}
                <span
                  class="spinner-border ml-2"
                  role="status"
                  v-show="loading"
                >
                  <span class="sr-only">Loading...</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerifyCalendarKyc from './VerifyCalendarKyc'
export default {
  components: { VerifyCalendarKyc },
  name: 'CalendarKyc',
  data () {
    return {
      selected_date: new Date(),
      show_slot: null,
      timeslots: [],
      step: 1,
      loading: false,
      kyc_data: null
    }
  },
  computed: {},
  watch: {
    selected_date (d) {
      this.prepareTimeSlot()
    }
  },
  mounted () {
    this.prepareTimeSlot()
    this.getDoctorKYC()
  },
  methods: {
    async getDoctorKYC () {
      let self = this
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getDoctorKYC()
        .then(r => {
          self.kyc_data = r.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    show () {
      window.$('#CalendarKyc').modal('show')
    },
    close () {
      window.$('#CalendarKyc').modal('hide')
    },
    back () {
      this.step = 1
    },
    showModal () {
      if (!this.show_slot) return
      this.step = 2
    },
    formatDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatDMYMinus (date) {
      return window.moment(date).format('DD-MM-YYYY')
    },
    formatHH (time) {
      return window.moment(time).format('HH:mm')
    },
    clickTime (time) {
      this.show_slot = time
    },
    prepareTimeSlot () {
      this.timeslots = []
      let is_today = window.moment().isSame(this.selected_date, 'dates')
      // gán start và end
      let start_time = window.moment('09:00', 'HH:mm')
      let end_time = window.moment('18:00', 'HH:mm')
      // console.log(session.start_time + '   ' + session.end_time)
      // console.log(start_time + '   ' + end_time)
      // vòng lặp lấy ra các slot từ start đến end
      let slot = 0
      var distance_time = 30
      var split_time = distance_time * 60000
      // slot = (end_time.valueOf() - start_time.valueOf()) / 900000
      slot = (end_time.valueOf() - start_time.valueOf()) / split_time
      for (var i = 1; i <= slot; i++) {
        // gán start và end của slot
        let start_slot = start_time
        let end_slot = window.moment(start_time)
        end_slot.add(distance_time, 'minutes')
        // end_slot.add(15, 'minutes')
        if (!is_today || start_slot.valueOf() > window.moment().valueOf()) {
          // gọi đến function để check các thời gian đã bị block
          this.timeslots.push({
            id: i,
            start_time: start_time.clone(),
            end_time: start_time.clone().add(distance_time, 'minutes')
          })
        }
        // kết thúc mỗi vòng lắp tăng 15 phút thời gian bắt đầu (start time)
        start_time.add(distance_time, 'minutes')
        // start_time.add(15, 'minutes')
      }
    },
    async createDoctorKYC () {
      let self = this
      if (!this.selected_date || !this.show_slot || !this.kyc_data) return
      let params = {
        meeting_date: this.formatDMYMinus(this.selected_date),
        meeting_start_time: this.show_slot.start_time.format('HH:mm:ss'),
        meeting_end_time: this.show_slot.end_time.format('HH:mm:ss')
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updateDoctorKYC(this.kyc_data.id, params)
        .then(r => {
          self.close()
          self.selected_date = new Date()
          self.show_slot = null
          self.$toast.open({
            message: this.$t('multidisciplinary_board.lbl_saved_msg'),
            type: 'success'
          })
          self.$emit('refresh')
          self.getDoctorKYC()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.appt-time-slot {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

.time-slot-df {
  border: solid 1px #20419b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-slot {
  height: 48px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}

.time-slot-mouseover .time {
  background-color: #8a8a8a;
}
.border-20px {
  border-radius: 20px;
}
</style>
